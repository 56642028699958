import { defineStore } from 'pinia'

interface UserState {
    fullName: string | null
    email: string | null
    username: string | null
    userId: string | null
}

export const useUserStore = defineStore('user', {
    state: (): UserState => ({
        fullName: null,
        email: null,
        username: null,
        userId: null
    }),

    actions: {
        setUser(userData: Partial<UserState>) {
            if (userData.fullName) this.fullName = userData.fullName
            if (userData.email) this.email = userData.email
            if (userData.username) this.username = userData.username
            if (userData.userId) this.userId = userData.userId
        },

        clearUser() {
            this.fullName = null
            this.email = null
            this.username = null
            this.userId = null
        }
    },

    persist: true
})
